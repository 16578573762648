import {
  ImageField,
  ImageInput,
  Labeled,
  maxLength,
  maxValue,
  minValue,
  required,
  SelectArrayInput,
  SelectInput,
  TextInput,
} from 'react-admin'
import React, { useEffect, useState } from 'react'
import { useInput } from 'ra-core'
import { useWatch } from 'react-hook-form'

interface PropertyInputProps {
  source: string
  label: string
  item?
}

const toChoices = items => {
  if (!items) return []
  return items.map(item => ({ id: item.id, name: item.value }))
}

export const PropertyInput: React.FC<PropertyInputProps> = ({
  label,
  source,
  item,
}) => {
  const validationOptions = []
  const type = item?.property?.type?.settings?.type
  const settings = item?.property?.type?.settings
  const choices = item?.property?.type?.valueVariants

  if (settings && !validationOptions.length) {
    const keys = (settingKey, value) => {
      const variantKeys = {
        maxLength: maxLength(value),
        min: minValue(value),
        max: maxValue(value),
      }
      if (!variantKeys[settingKey]) return null
      return variantKeys[settingKey]
    }

    for (const key in settings) {
      const addedOption = keys(key, settings[key])
      const existElement = validationOptions.find(e => {
        const firstElement = e?.toString()
        const secondElement = addedOption?.toString()
        return firstElement === secondElement
      })
      if (!addedOption || existElement) continue
      validationOptions.push(addedOption)
    }
  }

  const types = {
    default: (
      <Labeled label={label}>
        <TextInput label="" source={source} />
      </Labeled>
    ),
    string: (
      <Labeled label={label}>
        <TextInput label="" source={source} validate={[...validationOptions]} />
      </Labeled>
    ),
    integer: (
      <Labeled label={label}>
        <TextInput
          label=""
          type="number"
          source={source}
          validate={[...validationOptions]}
        />
      </Labeled>
    ),
    double: (
      <Labeled label={label}>
        <TextInput
          label=""
          type="number"
          source={source}
          validate={[...validationOptions]}
        />
      </Labeled>
    ),
    select: settings.multiple ? (
      <Labeled label={label}>
        <SelectArrayInput
          label=""
          source={source}
          choices={toChoices(choices)}
        />
      </Labeled>
    ) : (
      <Labeled label={label}>
        <SelectInput label="" source={source} choices={toChoices(choices)} />
      </Labeled>
    ),
    file: (
      <ImageInput
        source={source}
        label={label}
        multiple={item?.property?.type?.settings?.multiple}
        accept={item?.type?.settings?.mime}
      >
        <ImageField source="src" title="name" />
      </ImageInput>
    ),
  }
  return types[type] || types['default']
}

export const DynamicInput = props => {
  const watcher = useWatch({ name: 'properties' })
  const [choices, setChoices] = useState([])
  const [type, setType] = useState(null)
  const [multiple, setMultiple] = useState(null)
  const [symbolCode, setSymbolCode] = useState(null)
  const [validationOptions, setValidationOptions] = useState([])
  const index = +props.source.split('.')[1]
  const {
    field: { onChange },
  } = useInput({
    source: props.source,
  })

  useEffect(() => {
    if (!watcher[index]?.symbolCode) return

    const currentSymbolCode = watcher[index].symbolCode

    if (symbolCode !== currentSymbolCode) {
      setValidationOptions([])
    }

    setSymbolCode(currentSymbolCode)

    const currentTypeProperties = props.properties.find(
      e => e.symbolCode === currentSymbolCode
    )

    if (!currentTypeProperties) return

    setType(currentTypeProperties.type.settings.type)

    setChoices(currentTypeProperties.type.valueVariants)

    const settings = currentTypeProperties.type.settings
    if (settings?.type === 'file' || settings?.type === 'select') {
      setMultiple(settings?.multiple)
    }
    if (settings && !validationOptions.length) {
      const keys = (settingKey, value) => {
        const variantKeys = {
          maxLength: maxLength(value),
          min: minValue(value),
          max: maxValue(value),
        }
        if (!variantKeys[settingKey]) return null
        return variantKeys[settingKey]
      }
      const options = Object.keys(settings).flatMap(key => {
        const addedOption = keys(key, settings[key])
        const existElement = validationOptions.find(
          e => e.toString() === addedOption?.toString()
        )
        return addedOption && !existElement ? [addedOption] : []
      })
      setValidationOptions(prevOptions => [...prevOptions, ...options])
    }
  }, [watcher[index]?.symbolCode])

  const types = {
    default: <TextInput label="" source={props.source} />,
    string: (
      <TextInput
        label=""
        source={props.source}
        validate={[required(), ...validationOptions]}
      />
    ),
    integer: (
      <TextInput
        label=""
        type="number"
        source={props.source}
        validate={[required(), ...validationOptions]}
      />
    ),
    double: (
      <TextInput
        label=""
        type="number"
        source={props.source}
        validate={[required(), ...validationOptions]}
      />
    ),
    select: multiple ? (
      <SelectArrayInput
        label=""
        source={props.source}
        choices={toChoices(choices)}
      />
    ) : (
      <SelectInput
        label=""
        source={props.source}
        choices={toChoices(choices)}
      />
    ),
    file: (
      <ImageInput
        source={props.source}
        label={props.label}
        multiple={multiple}
        accept={['image/jpg', 'image/jpeg', 'image/png']}
      >
        <ImageField source="src" title="name" />
      </ImageInput>
    ),
  }
  return types[type] || types['default']
}
