import {
  Labeled,
  ReferenceInput,
  SelectInput,
  useRecordContext,
} from 'react-admin'
import React from 'react'

export const TrainerIdSelect = () => {
  return (
    <Labeled label="Тренер">
      <ReferenceInput
        source="userIdsByTypeId[1]"
        reference="users"
        filter={{ roles: [4] }}
      >
        <SelectInput
          label="Выберите id тренера"
          source="id"
          optionText={<FullNameField />}
        />
      </ReferenceInput>
    </Labeled>
  )
}

export const FullNameField = () => {
  const record = useRecordContext()

  return (
    <span>
      {record.id ?? ''}_
      {record.properties.find(el => el.symbolCode === 'name').value ?? ''}_
      {record.properties.find(el => el.symbolCode === 'surname').value ?? ''}
    </span>
  )
}

export const arrayResources = [
  'trainerGym',
  'trainingProgramDay',
  'trainingProgramDayExercise',
  'trainingProgram',
  'nutritionProgramDayMeal',
  'nutritionProgramDay',
  'nutritionProgram',
  'nutritionProgramDayMealDish',
  'stream',
]

export const excludeResourcesUseAndChat = [
  'survey'
]