import { Labeled, Loading, SelectInput, useRecordContext } from "react-admin"

export const SelectUserInput = () => {
  const record = useRecordContext()

  if(!record) return <Loading />

  const confirmationStatusIndex = Number(Object.entries(record.properties).find((prop: [string, any]) => prop[1].symbolCode === 'confirmationStatus')?.[0])

  return (
    <Labeled label="Статус проверки">
      <SelectInput
        label=""
        source={`properties.[${confirmationStatusIndex}].value`}
        choices={record.properties?.[confirmationStatusIndex]?.property?.type?.valueVariants}
        optionText="value"
        optionValue="id"
      />
    </Labeled>
  )
}