import { BooleanInput, SelectInput, TextInput } from "react-admin"
import React from "react"

export const InputFilter = (el: any) => {
  console.log(el)
  if(el.symbolCode !== 'images') {
    if(el?.type?.settings?.type === 'select') {
      return (
        <SelectInput
          label={el.name}
          source={`properties.${el.symbolCode}.value`}
          choices={el.type.valueVariants}
          optionText="value"
          optionValue="id"
        />
      )
    } else if(el?.type?.settings?.type === 'boolean') {
      return (
        <BooleanInput
          key={`${el.id}-${el.name}-${el.symbolCode}`}
          label={el.name}
          source={`properties.${el.symbolCode}.value`}
          defaultValue={true}
        />
      )
    } else {
      return (
        <TextInput label={`${el.name}`} source={`properties.${el.symbolCode}.value`}/>
      )
    }
  }
}