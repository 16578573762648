import { getFilter, searchFilterEntity } from '~/utils/filter'

export const parserParamsForGetList = (
  { pagination, sort, filter },
  resource: string
): object => {
  const { page, perPage } = pagination
  const { field, order } = sort
  const { query, ...newFilter } = searchFilterEntity(filter, resource)
  const applicationId = +sessionStorage.getItem('applicationId')


  const customOrder = (() => {
    if(resource === 'chats'){
      return [
        {
          field: 'hasUnreadMessage',
          by: 'DESC'
        },
        {
          field: 'lastMessageDate',
          by: 'DESC'
        }
      ]
    }
    if(Array.isArray(sort)){
      const arrayOrder = []
      for(let order of sort){
        arrayOrder.push({
          field: order.field,
          by: order.by
        })
      }
      return arrayOrder
    }
    return {
      field,
      by: order,
    }
  })()

  return {
    filter: getFilter(resource, newFilter, applicationId),
    pagination: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    order: customOrder,
    query,
  }
}

export const parserParamsForEntityGetList = ({
  pagination,
  sort,
  filter,
}): object => {
  const { page, perPage } = pagination
  const { field, order } = sort
  const fieldWithProperties = field.split('.')
  const fieldFromOrder =
    fieldWithProperties.length > 1 ? field.split('.')[1] : field
  const { query, ...newFilter } = searchFilterEntity(filter)

  if (filter.rawFilter) {
    return {
      filter: filter.rawFilter,
      pagination: {
        offset: (page - 1) * perPage,
        limit: perPage,
      },
      order: {
        field: fieldFromOrder,
        by: order,
      },
      scan: {
        field,
        query,
      },
    }
  }

  const relations = []

  if ('relations' in filter) {
    filter.relations.map(r => {
      const symbolCode = Object.keys(r)[0]
      relations.push({
        entity: [
          {
            symbolCode,
            id: r[symbolCode].id,
          },
        ],
      })
    })
    // @ts-ignore
    newFilter.relations = relations
  }

  if ('specialFilter' in filter) {
    return {
      filter: {
        relations: [
          {
            entity: {
              symbolCode: filter.specialFilter,
              exists: false,
            },
          },
        ],
      },
    }
  }

  return {
    filter: newFilter,
    pagination: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    order: {
      field: fieldFromOrder,
      by: order,
    },
    scan: {
      field,
      query,
    },
  }
}

export const parserParamsForGetManyReference = (
  { pagination, sort, id, target, filter },
  resource: string
): object => {
  const { page, perPage } = pagination
  const { field, order } = sort
  // const applicationId = +sessionStorage.getItem('applicationId')
  const newFilter = { ...filter, [target]: id }

  return {
    filter: getFilter(resource, newFilter),
    pagination: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    order: {
      field,
      by: order,
    },
  }
}
