import { Types, TypesEdit, TypeCreate } from '../resources/types'
import {
  Properties,
  PropertiesEdit,
  PropertyCreate,
} from '../resources/properties'
import { UserCreate, Users, UsersEdit } from '../resources/users'
import { RoleCreate, Roles, RolesEdit } from '../resources/roles'
import { LanguageCreate, Languages } from '../resources/languages'
import {
  ContentTextCreate,
  ContentTexts,
  ContentTextsEdit,
} from '../resources/content-texts'
import { ContentCreate, ContentEdit, Contents } from '../resources/content'
import {
  ContentTypeCreate,
  ContentTypeEdit,
  ContentTypes,
} from '../resources/content-types'
import {
  ContentProperties,
  ContentPropertyCreate,
  ContentPropertyEdit,
} from '../resources/content-properties'
import {
  ApplicationCreate,
  ApplicationEdit,
  Applications,
} from '../resources/applications'
import {
  EntityForApp,
  EntityForAppCreate,
  EntityForAppEdit,
  EntityForAppShow,
} from '../resources/entity-for-app'
import {
  EntitiesPropertiesTypes,
  EntitiesPropertiesTypesEdit,
  EntitiesPropertiesTypesCreate,
} from '../resources/entities-properties-types'
import {
  EntitiesProperties,
  EntitiesPropertiesEdit,
  EntitiesPropertiesCreate,
} from '../resources/entities-properties'
import {
  EntitiesTemplates,
  EntitiesTemplateEdit,
  EntitiesTemplateCreate,
} from '../resources/entities-templates'
import Icon from '@mui/icons-material/Settings'
import GymsIcon from '@mui/icons-material/FitnessCenterOutlined'
import ChatIcon from '@mui/icons-material/Chat'
import SportsIcon from '@mui/icons-material/Sports'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import LocalDiningIcon from '@mui/icons-material/LocalDining'
import ForumIcon from '@mui/icons-material/Forum'
import ReviewsIcon from '@mui/icons-material/Reviews'
import PollIcon from '@mui/icons-material/Poll'
import QuizIcon from '@mui/icons-material/Quiz'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import {
  EntityForModeration,
  EntityForModerationEdit,
  EntityForModerationShow,
} from '~/resources/entity-for-moderation'
import { Chats, ChatsEdit } from '~/resources/chats'
import { Withdraws, WithdrawsEdit } from '~/resources/withdraws'
import { UsersModeration, UsersModerationEdit, UsersModerationShow } from "~/resources/users-moderation"

/** системные */
const getSettingsResources = () => {
  return [
    {
      name: 'applications',
      list: Applications,
      edit: ApplicationEdit,
      create: ApplicationCreate,
      options: {
        label: 'Приложения',
      },
      icon: Icon,
    },
    {
      name: 'users-properties-types',
      list: Types,
      edit: TypesEdit,
      create: TypeCreate,
      options: {
        label: 'Типы пользовательских свойств',
      },
      icon: Icon,
    },
    {
      name: 'users-properties',
      list: Properties,
      edit: PropertiesEdit,
      create: PropertyCreate,
      options: {
        label: 'Свойства пользователей',
      },
      icon: Icon,
    },
    {
      name: 'roles',
      list: Roles,
      edit: RolesEdit,
      create: RoleCreate,
      options: {
        label: 'Роли',
      },
      icon: Icon,
    },
    {
      name: 'languages',
      list: Languages,
      create: LanguageCreate,
      options: {
        label: 'Языки',
      },
      icon: Icon,
    },
    {
      name: 'entities-properties-types',
      list: EntitiesPropertiesTypes,
      edit: EntitiesPropertiesTypesEdit,
      create: EntitiesPropertiesTypesCreate,
      options: {
        label: 'Типы свойств сущностей',
      },
      icon: Icon,
    },
    {
      name: 'entities-properties',
      list: EntitiesProperties,
      edit: EntitiesPropertiesEdit,
      create: EntitiesPropertiesCreate,
      options: {
        label: 'Свойства сущностей',
      },
      icon: Icon,
    },
    {
      name: 'entities-templates',
      list: EntitiesTemplates,
      edit: EntitiesTemplateEdit,
      create: EntitiesTemplateCreate,
      options: {
        label: 'Шаблоны сущностей',
      },
      icon: Icon,
    },
    {
      name: 'content-texts',
      list: ContentTexts,
      edit: ContentTextsEdit,
      create: ContentTextCreate,
      options: {
        label: 'Текстовый контент',
      },
      icon: Icon,
    },
    {
      name: 'content-entities',
      list: Contents,
      edit: ContentEdit,
      create: ContentCreate,
      options: {
        label: 'Сущности контента',
      },
      icon: Icon,
    },
    {
      name: 'content-entities-properties-types',
      list: ContentTypes,
      edit: ContentTypeEdit,
      create: ContentTypeCreate,
      options: {
        label: 'Типы свойств сущностей контента',
      },
      icon: Icon,
    },
    {
      name: 'content-entities-properties',
      list: ContentProperties,
      edit: ContentPropertyEdit,
      create: ContentPropertyCreate,
      options: {
        label: 'Свойства сущностей контента',
      },
      icon: Icon,
    },
  ]
}

export const resourceList = [
  ...getSettingsResources(),

  {
    showForAll: true,
    name: 'users',
    list: Users,
    edit: UsersEdit,
    create: UserCreate,
    options: {
      label: 'Пользователи',
    },
    icon: Icon,
  },

  // fitness app

  {
    applicationId: 1,
    name: '1:post',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Сообщество',
    },
    icon: ForumIcon,
  },
  {
    applicationId: 1,
    name: '1:gym',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Тренажерные залы',
    },
    icon: SportsIcon,
  },
  {
    applicationId: 1,
    name: '1:trainerGym',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Тренажерные залы тренера',
    },
    icon: SportsIcon,
  },
  {
    applicationId: 1,
    name: '1:exercise',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Cправочник упражнений',
    },
    icon: GymsIcon,
  },
  {
    applicationId: 1,
    name: '1:exerciseGroup',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Группы мышц упражнений',
    },
    icon: GymsIcon,
  },
  {
    applicationId: 1,
    name: '1:trainingProgramDay',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'День программы тренировок',
    },
    icon: GymsIcon,
  },
  {
    applicationId: 1,
    name: '1:trainingProgram',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Программа тренировок',
    },
    icon: GymsIcon,
  },
  {
    applicationId: 1,
    name: '1:training',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Тренировки',
    },
    icon: LocalDiningIcon,
  },
  // {
  //   applicationId: 1,
  //   name: '1:trainingProgramDayInTraining',
  //   list: EntityForApp,
  //   show: EntityForAppShow,
  //   edit: EntityForAppEdit,
  //   create: EntityForAppCreate,
  //   options: {
  //     label: 'День программы тренировок в тренировке',
  //   },
  //   icon: LocalDiningIcon,
  // },
  {
    applicationId: 1,
    name: '1:nutritionProgram',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Программы питания',
    },
    icon: LocalDiningIcon,
  },
  {
    applicationId: 1,
    name: '1:nutritionProgramDay',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'День программы питания',
    },
    icon: LocalDiningIcon,
  },
  {
    applicationId: 1,
    name: '1:nutritionProgramDayMeal',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Прием пищи дня программы питания',
    },
    icon: LocalDiningIcon,
  },
  // {
  //   applicationId: 1,
  //   name: '1:nutritionProgramDayMealDish',
  //   list: EntityForApp,
  //   show: EntityForAppShow,
  //   edit: EntityForAppEdit,
  //   create: EntityForAppCreate,
  //   options: {
  //     label: 'Блюдо приема пищи дня программы питания'
  //   },
  //   icon: LocalDiningIcon
  // },
  {
    applicationId: 1,
    name: '1:dish',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Блюдо',
    },
    icon: LocalDiningIcon,
  },
  {
    applicationId: 1,
    name: '1:product',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Продукты',
    },
    icon: LocalDiningIcon,
  },
  {
    applicationId: 1,
    name: '1:stream',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Стримы',
    },
    icon: GymsIcon,
  },
  {
    applicationId: 1,
    name: '1:review',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Отзывы',
    },
    icon: ReviewsIcon,
  },
  {
    applicationId: 1,
    name: '1:trainingProgram:moderation',
    list: EntityForModeration,
    show: EntityForModerationShow,
    edit: EntityForModerationEdit,
    options: {
      label: 'Модерация программ тренировок',
    },
    icon: GymsIcon,
  },
  {
    applicationId: 1,
    name: '1:nutritionProgram:moderation',
    list: EntityForModeration,
    show: EntityForModerationShow,
    edit: EntityForModerationEdit,
    options: {
      label: 'Модерация программ питания',
    },
    icon: LocalDiningIcon,
  },
  {
    applicationId: 1,
    name: '1:post:moderation',
    list: EntityForModeration,
    show: EntityForModerationShow,
    edit: EntityForModerationEdit,
    options: {
      label: 'Модерация постов',
    },
    icon: ForumIcon,
  },
  {
    applicationId: 1,
    name: 'users:moderation',
    list: UsersModeration,
    show: UsersModerationShow,
    edit: UsersModerationEdit,
    options: {
      label: 'Модерация тренеров',
    },
    icon: Icon,
  },
  {
    applicationId: 1,
    name: 'chats',
    list: Chats,
    edit: ChatsEdit,
    options: {
      label: 'Чаты',
    },
    icon: ChatIcon,
  },
  {
    applicationId: 1,
    name: 'withdraws',
    list: Withdraws,
    edit: WithdrawsEdit,
    options: {
      label: 'Запрос на вывод средств',
    },
    icon: CurrencyExchangeIcon,
  },
  {
    applicationId: 1,
    name: '1:survey',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Опросы',
    },
    icon: PollIcon,
  },
  {
    applicationId: 1,
    name: '1:surveyQuestion',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Вопросы',
    },
    icon: QuizIcon,
  },
  {
    applicationId: 1,
    name: '1:surveyAnswer',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Результаты опросов',
    },
    icon: QuestionAnswerIcon,
  },

  // MSA IT

  {
    applicationId: 3,
    name: '3:project',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Проекты',
    },
    // icon: CurrencyExchangeIcon,
  },

  {
    applicationId: 3,
    name: '3:projectComponent',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Составные части проекта',
    },
    // icon: CurrencyExchangeIcon,
  },

  {
    applicationId: 3,
    name: '3:projectComponentFormattedText',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Текст проекта',
    },
    // icon: CurrencyExchangeIcon,
  },
  {
    applicationId: 3,
    name: '3:projectComponentQuote',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Отзыв проекта',
    },
    // icon: CurrencyExchangeIcon,
  },

  {
    applicationId: 3,
    name: '3:projectComponentSlider',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Слайдер на проекте',
    },
    // icon: CurrencyExchangeIcon,
  },

  {
    applicationId: 3,
    name: '3:projectComponentSliderSlide',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Составыне части слайдера',
    },
    // icon: CurrencyExchangeIcon,
  },

  {
    applicationId: 3,
    name: '3:projectComponentVideo',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Видео проекта',
    },
    // icon: CurrencyExchangeIcon,
  },

  {
    applicationId: 3,
    name: '3:projectComponentDevelopers',
    list: EntityForApp,
    show: EntityForAppShow,
    edit: EntityForAppEdit,
    create: EntityForAppCreate,
    options: {
      label: 'Разработчики',
    },
    // icon: CurrencyExchangeIcon,
  },
  // Elon Task
]
