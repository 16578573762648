import {
  List,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  useResourceContext,
  Show,
  SimpleShowLayout,
  Labeled,
  TextInput,
  DateInput,
  Edit,
  SimpleForm, BulkDeleteWithConfirmButton, EditButton, DeleteButton
} from "react-admin"
import React, { useEffect, useState } from 'react'
import { getFieldForApp, getFieldForEditAndCreate } from '~/utils/getField'
import { EntityName } from '~/constants'
import { parseResource } from '~/modules/parse-resource'
import { resourceList } from '~/modules/resource-list'
import { ModerationTable } from '~/components/moderationTable/ModerationTable'

export const EntityForModeration = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()
  const alias = [
    'preview',
    'name',
    'description',
    'availabilityLevel',
    'confirmationStatus',
  ]

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  return (
    <List
      pagination={<PostPagination />}
      emptyWhileLoading
      filter={{
        properties: {
          confirmationStatus: { value: 2 },
        },
      }}
    >
      <Datagrid rowClick={'show'} size={'medium'}>
        <TextField label="ID" source="id" />
        <DateField label="Дата создания" source="createdAt" />
        <DateField label="Дата обновления" source="updatedAt" />

        {template.properties.map(
          el => alias.includes(el.symbolCode) && getFieldForApp(el)
        )}

        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

export const EntityForModerationEdit = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  const label = resourceList.find(el => el.name === resource)

  if (!template || !entityName) {
    return null
  }

  return (
    <Edit>
      <SimpleForm>
        <Labeled label={label?.options.label}>
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Дата создания">
          <DateInput label="" source="createdAt" readOnly />
        </Labeled>
        <Labeled label="Дата обновления">
          <DateInput label="" source="updatedAt" readOnly />
        </Labeled>
        {template.properties.map(
          el =>
            el.symbolCode === 'confirmationStatus' &&
            getFieldForEditAndCreate(el)
        )}
      </SimpleForm>
    </Edit>
  )
}

export const EntityForModerationShow = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  return (
    <Show queryOptions={{ meta: { fullTree: true } }}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <DateField label="Дата создания" source="createdAt" />
        <DateField label="Дата обновления" source="updatedAt" />
        {template.properties.map(el => getFieldForApp(el, 'show'))}
        <ModerationTable />
      </SimpleShowLayout>
    </Show>
  )
}

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)
